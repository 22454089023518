import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/price',
    name: 'price',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Price.vue')
  },
  {
    path: '/job',
    name: 'job',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Job.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Contacts.vue')
  },
  {
    path: '/reshenie',
    name: 'reshenie',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Reshenie.vue')
  },
  {
    path: '/sobranie',
    name: 'sobranie',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Sobranie.vue')
  },
  {
    path: '/sobranieview',
    name: 'sobranieview',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/SobranieView.vue')
  },
  {
    path: '/onas',
    name: 'onas',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Onas.vue')
  },
  {
    path: '/rukinfo',
    name: 'rukinfo',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/RukInfo.vue')
  },
  {
    path: '/cyberprest',
    name: 'cyberprest',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Cyberprest.vue')
  },
  {
    path: '/info',
    name: 'info',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Info.vue')
  },
  {
    path: '/infoview',
    name: 'infoview',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/InfoView.vue')
  },
  {
    path: '/otchet',
    name: 'otchet',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/Otchet.vue')
  },
  {
    path: '/otchetview',
    name: 'otchetview',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/OtchetView.vue')
  },
  {
    path: '/raskinfo',
    name: 'raskinfo',
    meta: {layout: 'HomeLayout', auth: false},
    component: () => import('@/views/RaskInfo.vue')
  },
  {
    path: '/admin_zapdvin',
    name: 'login',
    meta: {layout: 'LoginLayout', auth: false},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/admin_price',
    name: 'admin_price',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/admin/Price.vue')
  },
  {
    path: '/admin_akcioner',
    name: 'admin_akcioner',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/admin/Akcioner.vue')
  },
  {
    path: '/admin_infoview',
    name: 'admin_infoview',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/admin/InfoView.vue')
  },
  {
    path: '/admin_info',
    name: 'admin_info',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/admin/Info.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    store.dispatch('checkLogin').then(() => {
      if(store.getters.authStatus == 'success') {
        next()
      }
      if(store.getters.authStatus == 'error') {
        next({
          path: '/admin_zapdvin'
        })
      }
    })
  } else {
    next()
  }
})

export default router
