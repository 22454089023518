<template>
  <div>
    <div class="top-menu grid">

      <div class="logo grid">
        <q-img src="img/logo2.webp" style="width:90px"/>
      </div>

      <div class="text-center">

        <q-btn class="btn-main top-btn" @click="push('/')">{{ $t('items.item1') }}</q-btn>
        
        <q-btn class="top-btn" :label="$t('items.item2')">
          <q-menu
            :style="style"
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            <q-list>
              <q-item clickable v-for="(item, i) in $tm('nav.items1')" :key="i + 'item1'">
                <q-item-section @click="push(item.path)">{{item.title}}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

        <q-btn class="top-btn" :label="$t('items.item3')">
          <q-menu
            :style="style"
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            <q-list>
              <q-item clickable v-for="(item, i) in $tm('nav.items2')" :key="i + 'item1'">
                <q-item-section @click="push(item.path)">{{item.title}}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

        <q-btn @click="push('/info')" class="top-btn">{{ $t('items.item4') }}</q-btn>

        <q-btn @click="push('/price')" class="top-btn">{{ $t('items.item5') }}</q-btn>

        <q-btn @click="push('/job')" class="top-btn">{{ $t('items.item32') }}</q-btn>

        <q-btn @click="push('/contacts')" class="top-btn">{{ $t('items.item6') }}</q-btn>

      </div>

      <div class="contacts grid">
        <div class="tel"><q-icon left name="mdi-phone" /><a :href="`tel:${getInfo.tel}`">{{getInfo.tel}}</a></div>
        <div class="mail"><q-icon left name="mdi-email" /><a :href="`mailto:${getInfo.email}`">{{getInfo.email}}</a></div>
      </div>

    </div>

    <div>
    <div class="line-mmenu">
      <a class="btn-menu" @click="show">
        <q-icon v-if="!active" style="color:#f27937;" size="1.7em" name="mdi-menu" />
        <q-icon v-else style="color:#f27937;" size="1.7em" name="mdi-close-box" />
        <span>Меню</span>
      </a>
      <switchLang class="switchLang" />
    </div>
    <div class="top-menu-block">
      <ul>
        <li @click="push('/')">{{ $t('items.item1') }}</li>
        <li>
          <q-icon name="mdi-menu-down" />{{ $t('items.item2') }}
          <ul>
            <li 
              class="sub"
              v-for="(item1, i) in $tm('nav.items1')" 
              :key="i+'item1'"
              @click="push(item1.path)"
            >{{item1.title}}</li>
          </ul>
        </li>
        <li>
          <q-icon name="mdi-menu-down" />{{ $t('items.item3') }}
          <ul>
            <li 
              class="sub"
              v-for="(item2, i) in $tm('nav.items2')" 
              :key="i+'item2'"
              @click="push(item2.path)"
            >{{item2.title}}</li>
          </ul>
        </li>
        <li @click="push('/price')">{{ $t('items.item5') }}</li>
        <li @click="push('/info')">{{ $t('items.item4') }}</li>
        <li @click="push('/job')">{{ $t('items.item32') }}</li>
        <li @click="push('/contacts')">{{ $t('items.item6') }}</li>
      </ul>
    </div>
  </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import switchLang from '@/components/switchLang.vue'
export default {
  components: { switchLang },
  data: () => ({
    active: false,
    style: 'background: #232323;color:#ffffff;border-radius:0;margin-top:3px !important;position:absolute !important',
  }),
  methods: {
    push(path) {
      if(this.$route.path !== path) this.$router.push(path)
      if(document.body.classList.contains('m-active')) {
        this.show()
      }
    },
    show() {
      this.active = !this.active
      document.body.classList.toggle('m-active')
    }
  },
  computed: {
    ...mapGetters(['getInfo']),
  },
}
</script>

<style lang="scss" scoped>
  .btn-main {
    background-color: #f27937 !important;
    font-weight: bold;
  }
  .top-btn {
    border-radius: 0px;
    color: #ffffff;
    height: 50px;
    border: none;
    background-color: #232323;
  }
  .sub {
    text-transform: lowercase;
  }
  .top-menu {
    background: #232323;
    height: 50px;
    grid-template-columns: 3fr 9fr 3fr;
    text-align: left;
  }
  .logo {
    display: grid;
    z-index: 1;
    align-content: center;
    justify-content: center;
    margin-top: 4px;
  }
  .contacts {
    height: 50px;
    color: #ffffff;
    align-content: center;
    justify-content: center;
    span {
      color: red;
    }
  }
  .tel {
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
  .mail {
    a {
      color: #f27937;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 1253px) {
    .top-menu {
      display: none;
    }
  }

  .line-mmenu {
    display: none;
    position: fixed;
    z-index: 5;
    background-color: rgba(35, 35, 35, .9);
    width: 100%;
    transition: all .3s;
    top: 0;
    align-items: center;
    justify-content: left;
    font-size: 1.25em;
    .btn-menu {
      padding: 3px 0px;
      margin-left: 10px;
      cursor: pointer;
      span {
        color: #ffffff;
        padding: 6px 3px;
      }
      i {
        margin-bottom: 3px;
      }
    }
  }
  .top-menu-block {
    display: none;
    background-color: #232323;
    position: fixed;
    height: 100vh;
    width: 270px;
    z-index: 9000;
    transform: translateX(-270px);
    transition: all .3s;
    color: #ffffff;
    align-content: center;
    justify-content: center;
    text-align: left;
    top: 0;
    overflow: hidden;
    ul {
      text-transform: uppercase;
      li {
        list-style-type: none;
        cursor: pointer;
        margin: 10px 0;
        i {
          margin-left: -25px;
        }
      }
    }
  }
  .text-center {
    display: grid;
    height: 50px;
    grid-auto-flow: column;
  }
  @media only  screen and (max-width: 1253px) {
    .line-mmenu  {
      display: flex;
    }
    .top-menu-block {
      display: grid;
    }
  }
  .switchLang {
    top: 3px !important;
  }
</style>